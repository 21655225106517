import store from "@/store";
import { SET_BREADCRUMB } from "@/store/modules/BreadcrumbsModule";
/**
 * Sets current page breadcrumbs
 * @param {string} pageTitle Current page title
 * @param {Array<string>} breadcrumbs Current page breadcrumbs
 */
export const setCurrentPageBreadcrumbs = (pageTitle, breadcrumbs) => {
  store.dispatch(SET_BREADCRUMB, {
    title: pageTitle,
    pageBreadcrumbPath: breadcrumbs,
  });
};
/**
 * Sets current page breadcrumbs
 * @param {string} title Current page title name
 */
export const setCurrentPageTitle = (title) => {
  store.dispatch(SET_BREADCRUMB, {
    title: title,
  });
};
