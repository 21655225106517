import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
export default function useUsers() {
  const users = ref([]);
  const userRole = ref([]);
  const data_roles = ref([]);
  const user = ref();
  const router = useRouter();
  const getUsers = async (page) => {
    return await ApiService.query("users",page);

  };
  const getRoles = async () => {
    let response = await ApiService.get("roles");
    data_roles.value = response.data.data;
  };

  const getUser = async (id) => {
    let response = await ApiService.get("users/" + id);

    user.value = response.data.data.user;
    data_roles.value = response.data.data.roles;
    userRole.value = response.data.data.userRole;
  };

  const destroyUser = async (id) => {
    await ApiService.delete("users/" + id);
  };

  const stoerUser = async (data) => {
    await ApiService.post("users", data);
    await router.push({ name: "settings.users.index" });
  };

  const updateUser = async (data) => {
    await ApiService.put("users/" + data.id, data);
    await router.push({ name: "settings.users.index" });
  };

  return {
    users,
    data_roles,
    userRole,
    getUsers,
    getRoles,
    getUser,
    user,
    destroyUser,
    stoerUser,
    updateUser,
  };
}
